import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

interface usePreloaderProps {
    isActive: Ref<boolean>,
    stopPreloader: () => void;
    startPreloader: () => void;
}

export const usePreloader = defineStore('preloader', (): usePreloaderProps => {
  const isActive = ref(false);

  const stopPreloader = () => {
    isActive.value = false;
  }

  const startPreloader = () => {
    isActive.value = true;
  }

  return {
    isActive,
    stopPreloader,
    startPreloader,
  }
});