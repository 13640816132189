import { routerPaths } from '~/shared/config/router';

export const MENU_LINKS = {
  TERMS_AND_CONDITIONS: '/pages/terms-and-conditions',
  SAFETY: '/pages/safety',
  PRIVACY_POLICY: '/pages/privacy-policy',
  FAQ: '/pages/faq',
  MEMBERSHIP_AND_REFUND_POLICY: '/pages/membership-and-refund-policy',
  COOKIE_POLICY: '/pages/cookie-policy',
};

export const MENU_ITEMS = [
  { label: 'Terms and Conditions', link: MENU_LINKS.TERMS_AND_CONDITIONS },
  { label: 'Safety', link: MENU_LINKS.SAFETY },
  { label: 'Privacy Policy', link: MENU_LINKS.PRIVACY_POLICY },
  { label: 'FAQ', link: MENU_LINKS.FAQ },
  { label: 'Membership and Refund Policy', link: MENU_LINKS.MEMBERSHIP_AND_REFUND_POLICY },
  { label: 'Cookie Policy', link: MENU_LINKS.COOKIE_POLICY },
  { label: 'Support', link: routerPaths.support }
];
